.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.col {
  flex-direction: column;
}

.flex_1 {
  display: flex;
  flex: 1;
}

.center {
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

@for $i from 5 to 40 by 5{
    .marginL-$i { margin-left: $(i)px; }
    .marginR-$i { margin-right: $(i)px; }
    .marginH-$i { margin-left: $(i)px; margin-right: $(i)px; }

    .marginT-$i { margin-top: $(i)px; }
    .marginB-$i { margin-bottom: $(i)px; }
    .marginV-$i { margin-top: $(i)px; margin-bottom: $(i)px; }

    .paddingL-$i { padding-left: $(i)px; }
    .paddingR-$i { padding-right: $(i)px; }
    .paddingH-$i { padding-left: $(i)px; padding-right: $(i)px; }

    .paddingT-$i { padding-top: $(i)px; }
    .paddingB-$i { padding-bottom: $(i)px; }
    .paddingV-$i { padding-top: $(i)px; padding-bottom: $(i)px; }
}

/* ANTD OVERRIDE */
.ant-layout {
  background: transparent;
}

.ant-layout-header {
  min-height: 100px;
  height: auto;
}

.ant-form-explain, .ant-form-extra {
  margin-top: 0px;
}

.ant-checkbox-wrapper {
  line-height: normal;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}

body {
  background: #FFFFFF !important;
  font-family:'Swiss721-MD-BT', 'Baskerville', 'Swiss721-Roman';
}

.MuiCircularProgress-colorPrimary {
  color: #095d7c !important;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../src/resources/fonts/Raleway/Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Baskerville';
  src: local('Baskerville'), url(../src/resources/fonts/Baskerville/ITC-NewBaskerville-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Baskerville-Bold';
  src: local('Baskerville-Bold'), url(../src/resources/fonts/Baskerville/ITC-New-Baskerville-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Swiss721-MD-BT';
  src: local('Swiss721-MD-BT'), url(../src/resources/fonts/Swis721/Swiss721MediumBT.ttf) format('truetype');
}

@font-face {
  font-family: 'Swiss721-LT-BT';
  src: local('Swiss721-LT-BT'), url(../src/resources/fonts/Swis721/Swiss721LightBT.ttf) format('truetype');
}

@font-face {
  font-family: 'Swiss721-BT-Heavy';
  src: local('Swiss721-BT-Heavy'), url(../src/resources/fonts/Swis721/Swiss721BTHeavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Swiss721-Roman';
  src: local('Swiss721-Roman'), url(../src/resources/fonts/Swis721/Swiss-721-Roman.otf) format('truetype');
}
@media print {
  @page {
    margin: 0;
    size: a4 portrait !important;
  }

  .print-only {
    margin-top: 20px;
    display: block;
  }
}